import './App.css'; // Archivo de estilos CSS
import React, { useState, useEffect } from 'react';
import libros from './db/libros.json'; // Asegúrate de que la ruta es correcta

function App() {
  const renderLibros = () => {
    return libros.map((libro, index) => (
      <div key={index} className="card">
        <h2 className="card-title">{libro}</h2>
        <a href={`/db/${libro}`} className="button" download>
          Descargar
        </a>
      </div>
    ));
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Lista de Libros</h1>
      </header>
      <div className="container">
        {renderLibros()}
      </div>
    </div>
  );
}

export default App;
